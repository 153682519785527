/* eslint-disable */
import axios from 'axios';

const BASE_URL = 'http://3.122.6.205:8091/magazine/';

export const getToken = () => {
  const tokenString = localStorage.getItem('token');
  return tokenString
}

export const setToken = (userToken) => {
  localStorage.setItem('token', userToken);
}

export const removeToken = () => {
  localStorage.removeItem('token')
}

export const isLoggedIn = () => {
  return getToken()
}

const api = axios.create({
    headers: {
        "Content-type": "application/json"
    }
})

  api.interceptors.response.use(
    async (config) => {
      if(config.data.accessToken){
          setToken("Bearer " + config.data.accessToken)
      }
      const token = await getToken()
      if (token) {
        config.headers["Authorization"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  api.interceptors.request.use(
    async (config) => {
      const token = await getToken()
      if (token !== 'null' || token !== 'Bearer undefined') {
        config.headers["Authorization"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export const get = async (url, headers = {}) => {
    return api.get(BASE_URL + url)
}

export const post = async (url, body = {}, query = '') => {
    return api.post(BASE_URL + url + query, body)
        .then((res) => res.data)
        .catch((res) => Promise.reject(res.response.data.message))
}

export const put = async (url, body = {}, query = '') => {
    return api.put(BASE_URL + url + query, body)
    .then(res => res.data)
}

export const deletion = async (url) => {
    return api.delete(BASE_URL + url)
    .then(res => res.data)
}

